(function() {
 window['JST'] = window['JST'] || {};
})();
function IndexPage() {
  var _i = this;

  _i.max_shown = 6;
  _i.userImagePixelDim = 172;
  _i.scaleFactor = 4;
  _i.entitlementsBanner = null;
  _i.changedFields = {};
  _i.userEntitlementsEnabled = false;
  _i.userIsLimitedResource = false;
  _i.isCribEligible = false;

  _i.locationsData = [];
  _i.allowProfileUpdates =
    _i.userEntitlementsEnabled && _i.userIsLimitedResource && _i.isCribEligible;

  _i.archiveButtonCallback = function () {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    window.location.href = getBaseURL() + 'people';
  };

  _i.unarchiveButtonCallback = function () {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    var userid = '?edituser=true';
    if (_i.userIdFromQueryString()) {
      userid += '&user_id=' + _i.userIdFromQueryString();
    }
    _i.navigateToUserProfile();
  };

  _i.archiveButtonClickAction = function () {
    if (_i.isInAsynCall) return;
    var data = {};
    data.id = _i.user.id;
    _i.isInAsynCall = new pageSpinner();
    gService.deleteUser(data, _i.archiveButtonCallback);
  };

  _i.archiveButtonClick = function () {
    if (_i.user.deleted) {
      if (_i.isInAsynCall) return;
      var data = {};
      data.id = _i.user.id;
      _i.isInAsynCall = new pageSpinner();
      gService.undeleteUser(
        data,
        _i.unarchiveButtonCallback,
        function (responseMessage) {
          _i.isInAsynCall.remove();
          if (
            responseMessage.includes('A profile with this email already exists')
          ) {
            new notificationPopup(
              I18n.t('err_duplicate_email'),
              I18n.t('err_unexpected_issue_html', {
                supportUrl: window.SMARTSHEET_SUPPORT_URL,
              }),
              6000
            );
          } else {
            new notificationPopup(
              responseMessage,
              I18n.t('msg_visit_billing'),
              6000
            );
          }
        }
      );
    } else {
      if (_i.user.account_owner) {
        new notificationPopup(
          I18n.t('msg_cannot_archive_account_owner'),
          I18n.t('msg_change_account_owner'),
          15000
        );
      } else {
        var confirmDelete = confirmationPopup(
          I18n.t('lbl_archive') +
            ' ' +
            _i.user.firstName +
            ' ' +
            _i.user.lastName +
            '?',
          I18n.t('lbl_archive'),
          function () {
            _i.archiveButtonClickAction();
          },
          null,
          null
        );
        confirmDelete.show(_i.archive.container);
      }
    }
  };

  _i.deleteCallback = function () {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    window.location.href = getBaseURL() + 'settings';
  };

  _i.deleteUser = function () {
    if (_i.isInAsynCall) return;
    _i.isInAsynCall = new pageSpinner();
    gService.fulldeleteUser(_i.user, _i.deleteCallback);
  };

  _i.deleteButtonClick = function () {
    var confirmDelete = confirmationPopup(
      I18n.t('msg_delete_user_confirm'),
      I18n.t('lbl_delete'),
      _i.deleteUser,
      null,
      null,
      null,
      150
    );
    confirmDelete.show(_i.deleteButton.container);
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-15 indexSpan';
    ret.innerText = str;
    return ret;
  };

  _i.valueFromInput = function (val) {
    if (val == '') return 0;
    if (!val) return 0;
    val = convertCurrencyToNumber(val);
    val = parseFloat(val);

    return isNumber(val) ? val : 0;
  };

  _i.passwordReset = function (obj) {
    if (obj && !obj.error && !_i.user.invitation_pending && _i.user.login) {
      notificationPopup(
        I18n.t('lbl_password_reset'),
        I18n.t('msg_password_reset_confirm'),
        5000
      );
    } else if (!_i.user.login) {
      notificationPopup(
        I18n.t('lbl_invitation_sent'),
        I18n.t('msg_invitation_sent_confirm'),
        5000
      );
      _i.user.invitation_pending = true;
      if (_i.parent) _i.parent.refreshList();
    } else if (_i.user.invitation_pending) {
      notificationPopup(
        I18n.t('lbl_invitation_sent'),
        I18n.t('msg_invite_resent'),
        5000
      );
    }
  };

  _i.passwordResetError = function (err) {
    var message;
    try {
      message = JSON.parse(err.responseText).message;
    } catch (ex) {
      message =
        'Error accepting password reset request. Please contact support';
    }
    notificationPopup(I18n.t('lbl_reset_password'), message, 5000);
  };

  _i.sendInvitationEmail = function () {
    if (_i.user.invitation_pending) {
      var confirm = confirmationPopup(
        I18n.t('lbl_resend_invitation_to') + ' ' + _i.email.getValue() + '?',
        I18n.t('lbl_resend'),
        function () {
          gService.resendInvite(
            _i.user.id,
            _i.passwordReset,
            _i.email.getValue()
          );
        },
        null,
        null
      );
      confirm.show(_i.action.container);
    } else if (_i.user.email == null) {
      window.location.href =
        getBaseURL() +
        'sendinvite?ret=user&user_id=' +
        _i.user.id +
        '&guid=' +
        _i.user.guid;
    } else {
      var confirm = confirmationPopup(
        I18n.t('lbl_send_invitation_to') + ' ' + _i.email.getValue() + '?',
        I18n.t('lbl_send'),
        function () {
          gService.resendInvite(
            _i.user.id,
            _i.passwordReset,
            _i.email.getValue()
          );
        },
        null,
        null
      );
      confirm.show(_i.action.container);
    }
  };

  _i.resetPassword = function () {
    {
      var confirm = confirmationPopup(
        I18n.t('lbl_reset_password_for') +
          ' ' +
          _i.user.firstName +
          ' ' +
          _i.user.lastName +
          '?',
        I18n.t('lbl_reset'),
        function () {
          var passwordreset = {};
          passwordreset.email = _i.user.email;
          gService.passwordreset(
            passwordreset,
            _i.passwordReset,
            _i.passwordResetError
          );
        },
        null,
        null
      );
      confirm.show(_i.action.container);
    }
  };

  _i.userCancelButtonCallback = function () {
    var userid = '';
    if (_i.userIdFromQueryString()) {
      userid = '?user_id=' + _i.userIdFromQueryString();
    }
    _i.navigateToUserProfile();
  };

  _i.userSaved = function (obj) {
    if (_i.availability) {
      _i.availability.saveEverything(_i.availabilitiesSaved);
    } else {
      _i.navigateToUserProfile();
    }
  };

  _i.availabilitiesSaved = function (obj) {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    _i.navigateToUserProfile();
  };

  _i.navigateToUserProfile = function () {
    window.location.href = getBaseURL() + 'users/' + _i.user.id;
  };

  _i.setLocationTag = function (obj) {
    if (
      _i.location.selected_value != null &&
      _i.location.input_data[_i.location.selected_value].tag
    ) {
      obj.location = _i.location.input_data[_i.location.selected_value].tag;
    } else if (
      _i.location.selected_value == null &&
      _i.location.displayedValue() &&
      _i.location.displayedValue() != ''
    ) {
      var newtag = _i.location.displayedValue();
      var addtag = true;
      var tag = null;
      // search existing location tags and see if we are creating a new one
      var tags = gData.getTagsByNamespace('location');
      if (tags) {
        for (var i = 0; i < tags.length; i++) {
          if (
            tags[i].value.toLowerCase() === newtag.toLowerCase() &&
            tags[i].namespace === 'location'
          ) {
            addtag = false;
            obj.location = tags[i];
            break;
          }
        }
      }
      if (addtag) {
        obj.location = {};
        obj.location.namespace = 'location';
        obj.location.name = newtag;
        obj.location.value = newtag;
      }
    }
  };

  _i.setDisciplineTag = function (obj) {
    if (
      _i.discipline.selected_value != null &&
      _i.discipline.input_data[_i.discipline.selected_value].tag
    ) {
      obj.discipline =
        _i.discipline.input_data[_i.discipline.selected_value].tag;
    } else if (
      _i.discipline.selected_value == null &&
      _i.discipline.displayedValue() &&
      _i.discipline.displayedValue() != ''
    ) {
      var newtag = _i.discipline.displayedValue();
      var addtag = true;
      var tag = null;
      // search existing discipline tags and see if we are creating a new one
      var tags = gData.getTagsByNamespace('discipline');
      if (tags) {
        for (var i = 0; i < tags.length; i++) {
          if (
            tags[i].value.toLowerCase() === newtag.toLowerCase() &&
            tags[i].namespace === 'discipline'
          ) {
            addtag = false;
            obj.discipline = tags[i];
            break;
          }
        }
      }
      if (addtag) {
        obj.discipline = {};
        obj.discipline.namespace = 'discipline';
        obj.discipline.name = newtag;
        obj.discipline.value = newtag;
      }
    }
  };

  _i.setRoleTag = function (obj) {
    if (
      _i.role.selected_value != null &&
      _i.role.input_data[_i.role.selected_value].tag
    ) {
      obj.role = _i.role.input_data[_i.role.selected_value].tag;
    } else if (
      _i.role.selected_value == null &&
      _i.role.displayedValue() &&
      _i.role.displayedValue() != ''
    ) {
      var newtag = _i.role.displayedValue();
      var addtag = true;
      var tag = null;
      // search existing role tags and see if we are creating a new one
      var tags = gData.getTagsByNamespace('resource type');
      if (tags) {
        for (var i = 0; i < tags.length; i++) {
          if (
            tags[i].value.toLowerCase() === newtag.toLowerCase() &&
            tags[i].namespace === 'resource type'
          ) {
            addtag = false;
            obj.role = tags[i];
            break;
          }
        }
      }
      if (addtag) {
        obj.role = {};
        obj.role.namespace = 'resource type';
        obj.role.name = newtag;
        obj.role.value = newtag;
      }
    }
  };

  _i.getTagValuesForNamespace = function (tags, namespace) {
    var values = [];
    tags &&
      tags.forEach(function (t) {
        if (t.namespace == namespace) {
          values.push(t.value);
        }
      });
    return values;
  };

  _i.shouldRequireEmail = function () {
    return _i.user.invitation_pending || _i.user.login;
  };

  _i.verifyString = function (str) {
    return str != null && str != '' && str.trim() != '';
  };

  _i.verifyAvailability = function () {
    // Sort the availabilities by start dates. Also, incldues null start and end dates such that they will always be first in the ascending ordered list
    var currentAvailabilities = _i.availability.availabilities.slice();
    currentAvailabilities.sort(function (a, b) {
      var dateA = a.startdate.selectedDate
        ? a.startdate.selectedDate.toRubyDateYMD()
        : null;
      var dateB = b.startdate.selectedDate
        ? b.startdate.selectedDate.toRubyDateYMD()
        : null;
      if (dateA == null) {
        return -1;
      } else if (dateB == null) {
        return 1;
      } else {
        return dateA < dateB ? -1 : 1;
      }
    });

    // Check for overlapping dates
    for (var i = 0; i < currentAvailabilities.length - 1; i++) {
      var availability1 = currentAvailabilities[i];
      var availability2 = currentAvailabilities[i + 1];

      // if any one of them is marked for deletion, skip
      if (availability1.isDeleted || availability2.isDeleted) {
        continue;
      }

      var avilability1StartDate = availability1.startdate.selectedDate
        ? availability1.startdate.selectedDate.toRubyDateYMD()
        : null;
      var avilability1EndDate = availability1.enddate.selectedDate
        ? availability1.enddate.selectedDate.toRubyDateYMD()
        : null;
      var avilability2StartDate = availability2.startdate.selectedDate
        ? availability2.startdate.selectedDate.toRubyDateYMD()
        : null;
      var avilability2EndDate = availability2.enddate.selectedDate
        ? availability2.enddate.selectedDate.toRubyDateYMD()
        : null;

      // first availability has no start date but there is an end date < second availability start date
      if (
        !avilability1StartDate &&
        avilability1EndDate &&
        avilability2StartDate &&
        avilability2EndDate &&
        avilability1EndDate < avilability2StartDate
      ) {
        // valid date
        continue;
      }

      // first availability has no start date and second availability has no end date but first one's end date < second one's start date
      if (
        !avilability1StartDate &&
        avilability1EndDate &&
        avilability2StartDate &&
        !avilability2EndDate &&
        avilability1EndDate < avilability2StartDate
      ) {
        // valid date
        continue;
      }

      // second availability has no end date but first one's end date < second one's start date
      if (
        avilability1StartDate &&
        avilability1EndDate &&
        avilability2StartDate &&
        !avilability2EndDate &&
        avilability1EndDate < avilability2StartDate
      ) {
        // valid date
        continue;
      }

      // all values exist and first one's end date < second one's start date
      if (
        avilability1StartDate &&
        avilability1EndDate &&
        avilability2StartDate &&
        avilability2EndDate &&
        avilability1EndDate < avilability2StartDate
      ) {
        // valid date
        continue;
      }

      // Overlapping dates found
      return false;
    }
    return true;
  };

  _i.saveUser = function () {
    let upgradePromise = Promise.resolve();
    if (_i.anyChangedLRFields()) {
      upgradePromise = gService.upgradeUser(_i.user.id, _i.userUpdateError);
    }

    upgradePromise.then((success = true) => {
      if (!success) {
        return;
      }

      var userdata = {};
      if (_i.firstName) {
        userdata.firstName = _i.firstName.getValue();
      } else {
        userdata.firstName = _i.user.firstName;
      }
      if (_i.lastName) {
        userdata.lastName = _i.lastName.getValue();
      } else {
        userdata.lastName = _i.user.lastName;
      }

      if (_i.email) {
        userdata.email = _i.email.getValue();
      } else {
        userdata.email = _i.user.email;
      }

      if (_i.mobilePhone) {
        userdata.mobile_phone = _i.mobilePhone.getValue();
      } else {
        userdata.mobile_phone = _i.user.mobilePhone;
      }
      if (_i.officePhone) {
        userdata.office_phone = _i.officePhone.getValue();
      } else {
        userdata.office_phone = _i.user.officePhone;
      }

      userdata.hire_date = _i.start_date; // Always send as a null means that we are resetting the date.
      userdata.termination_date = _i.end_date;
      userdata.employee_number = _i.employeeNumber.getValue();

      if (gData.accountSettings.moduleEnabled('relationship-based approvals')) {
        userdata.approver_user_ids = window.selectedApproverUserIds;
      }

      if (_i.awsUpload && _i.awsUpload.hasThumbnailChanged) {
        userdata.thumbnail = _i.awsUpload.getUrl();
      }

      _i.setLocationTag(userdata);
      if (
        gData.accountSettings.canUserEditModel(
          gData.getMe(),
          'AccountSetting',
          null
        )
      ) {
        if (_i.selectionBillrateDefault) userdata.billrate = -1;
        else
          userdata.billrate = _i.valueFromInput(
            _i.custombillrate.inputText.value
          );
        if (_i.usertypeid) userdata.user_type_id = _i.usertypeid;
        var target = _i.billabilitytarget.getValue();
        if (isNumber(target))
          userdata.billability_target = _i.billabilitytarget.getValue();
        _i.setDisciplineTag(userdata);
        _i.setRoleTag(userdata);
      }
      if (_i.tagEdit) {
        userdata.tags = _i.getTagValuesForNamespace(_i.user.tags, 'users');
      }
      userdata.id = _i.user.id;

      var customFieldValues = [];
      if (_i.customFieldsEdit) {
        customFieldValues = _i.customFieldsEdit.getCustomFieldIdsAndValues();
      }

      if (_i.adminCustomFieldsEdit) {
        customFieldValues = customFieldValues.concat(
          _i.adminCustomFieldsEdit.getCustomFieldIdsAndValues()
        );
      }

      if (_i.customFieldsEdit || _i.adminCustomFieldsEdit) {
        userdata.custom_field_values = customFieldValues;
        ARC.expire('custom_field_values');
      }

      // validate availabilities don't overlap
      if (_i.availability) {
        if (!_i.verifyAvailability()) {
          _i.availability.container.scrollIntoView();
          alertPopup(
            I18n.t('err_availability_overlap'),
            _i.availability.container
          ).show(_i.availability.container);
          return;
        }
      }

      // Validate blank space
      if (!_i.verifyString(userdata.firstName)) {
        _i.firstName.inputText.scrollIntoView();
        _i.firstName.setAlert(I18n.t('err_valid_first_name'));
      } else if (!_i.verifyString(userdata.lastName)) {
        _i.lastName.inputText.scrollIntoView();
        _i.lastName.setAlert(I18n.t('err_valid_last_name'));
      } else {
        _i.isInAsynCall = new pageSpinner();
        gService.updateUser(userdata, _i.userSaved, _i.userUpdateError);
      }
    });
  };

  _i.userUpdateError = function (error) {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    if (error.includes('A profile with this email already exists')) {
      new notificationPopup(
        I18n.t('err_duplicate_email'),
        I18n.t('err_unexpected_issue_html', {
          supportUrl: window.SMARTSHEET_SUPPORT_URL,
        }),
        6000
      );
    } else
      new notificationPopup(
        error,
        I18n.t('err_unexpected_issue_html', {
          supportUrl: window.SMARTSHEET_SUPPORT_URL,
        }),
        6000
      );
  };

  _i.userSaveButtonCallback = function () {
    if (_i.isInAsynCall) {
      return;
    }

    // if the email address is editable, and we showed the form field
    if (_i.email) {
      // We want to make sure you cannot set a user email to "" if they have been invited
      // or if they have a login
      if (!verifyEmail(_i.email.getValue(), _i.shouldRequireEmail())) {
        _i.email.inputText.scrollIntoView();
        _i.email.setAlert(I18n.t('err_valid_email'));
        return false;
      }
    }
    // Save user if valid
    if (_i.awsUpload && _i.awsUpload.hasThumbnailChanged) {
      _i.sendToS3Action();
    } else {
      _i.saveUser();
    }
  };

  _i.sendToS3Callback = function () {
    _i.saveUser();
  };

  _i.sendToS3FailureCallback = function () {
    if (_i.isInAsynCall) {
      _i.isInAsynCall.remove();
      _i.isInAsynCall = null;
    }
    new notificationPopup(
      I18n.t('err_unexpected_issue_html', {
        supportUrl: window.SMARTSHEET_SUPPORT_URL,
      }),
      'Image upload failed',
      10000
    );
  };

  _i.sendToS3Action = function () {
    if (_i.isInAsynCall) return;
    _i.isInAsynCall = new pageSpinner();
    var saveDim = _i.userImagePixelDim * _i.scaleFactor;
    _i.awsUpload.processAndSendImage(
      saveDim,
      saveDim,
      _i.userThumb.thumbnail,
      _i.sendToS3Callback,
      _i.sendToS3FailureCallback
    );
  };

  _i.onUserTypeChanged = function (obj) {
    if (obj[0].id != null) {
      _i.usertypeid = obj[0].id;
      _i.userType.setValue(obj[0].value);
    }
  };

  _i.toggleCallbackBillrateDefault = function () {
    if (!_i.selectionBillrateDefault) {
      _i.selectionBillrateDefault = true;
      _i.selectionbillratedefault.setEnabled(true);
      _i.selectionbillratecustom.setEnabled(false);
      _i.custombillrate.inputText.value = '';
    }
  };

  _i.toggleCallbackBillrateCustom = function () {
    if (_i.selectionBillrateDefault) {
      _i.selectionBillrateDefault = false;
      _i.selectionbillratedefault.setEnabled(false);
      _i.selectionbillratecustom.setEnabled(true);
      if (_i.user.billrate != -1)
        _i.custombillrate.inputText.value = formatCurrency(_i.user.billrate);
      _i.custombillrate.inputText.focus();
    }
  };

  _i.onLocationChanged = function (obj) {
    if (obj[0].id == 'newvalue') {
      _i.location.focusTextInput();
      _i.location.selected_value = null;
      return;
    }
    _i.location.setValue(_i.matchLocationToData(obj[0].value));
    _i.locationLRChangeListener(obj[0].id);
  };

  _i.onDisciplineChanged = function (obj) {
    if (obj[0].id == 'newvalue') {
      _i.discipline.focusTextInput();
      _i.discipline.selected_value = null;
      return;
    }
    _i.discipline.setValue(_i.matchDisciplineToData(obj[0].value));
    _i.disciplineLRChangeListener(obj[0].id);
  };

  _i.onRoleChanged = function (obj) {
    if (obj[0].id == 'newvalue') {
      _i.role.focusTextInput();
      _i.role.selected_value = null;
      return;
    }
    _i.role.setValue(_i.matchRoleToData(obj[0].value));
    _i.roleLRChangeListener(obj[0].id);
  };

  _i.matchRoleToData = function (value) {
    for (i = 0; i < _i.roleData.length; i++) {
      if (_i.roleData[i].value == value) return i;
    }
    return 0;
  };

  _i.matchRoleToId = function (id) {
    for (i = 0; i < _i.roleData.length; i++) {
      if (_i.roleData[i].id == id) return i;
    }
    return 0;
  };

  _i.matchDisciplineToData = function (value) {
    for (i = 0; i < _i.disciplineData.length; i++) {
      if (_i.disciplineData[i].value == value) return i;
    }
    return 0;
  };

  _i.matchDisciplineToId = function (id) {
    for (i = 0; i < _i.disciplineData.length; i++) {
      if (_i.disciplineData[i].id == id) return i;
    }
    return 0;
  };

  _i.matchLocationToData = function (value) {
    for (i = 0; i < _i.locationsData.length; i++) {
      if (_i.locationsData[i].value == value) return i;
    }
    return 0;
  };

  _i.matchLocationToId = function (id) {
    for (i = 0; i < _i.locationsData.length; i++) {
      if (_i.locationsData[i].id == id) return i;
    }
    return 0;
  };

  _i.userIdFromQueryString = function () {
    var query = window.location.search.substring(1);
    var parms = query.split('&');
    var ret = null;

    for (var i = 0; i < parms.length; i++) {
      var pos = parms[i].indexOf('=');
      if (pos > 0) {
        var key = parms[i].substring(0, pos);
        var val = parms[i].substring(pos + 1);
        if (key == 'user_id') {
          ret = parms[i].substring(pos + 1);
        }
      }
    }
    return ret;
  };

  _i.initPage = function () {
    if (gPageHeader) gPageHeader.setSpinner(true);
    gDataLoading = true;

    _i.impModeEnabled = inImpMode();
    ARC.expire('users');
    gService.getUserEditPageData(_i.editPageDataLoaded);
  };

  _i.editPageDataLoaded = function (obj) {
    var user_id = _i.userIdFromQueryString();
    if (user_id) {
      _i.user = gData.getUserByGuid(user_id);
    } else {
      _i.user = gData.getMe();
    }
    _i.userEntitlementsEnabled = window.gData.accountSettings.moduleEnabled(
      'crib_user_entitlement'
    );
    _i.userIsLimitedResource = _i.user.license_type === 'unmanaged_resource';
    _i.isCribEligible = window.gData.accountSettings.crib_feature_eligibility;
    _i.allDataLoaded(obj);
  };

  _i.parseTagsForString = function () {
    var ret = '';
    for (var i = 0; i < _i.user.tags.length; i++) {
      tag = _i.user.tags[i];
      if (tag.namespace == 'users') {
        if (ret != '') {
          ret += ', ';
        }
        ret += tag.value;
      }
    }
    return ret;
  };

  _i.parseTagsForPopup = function (namespaceString, sort) {
    var ret = [];
    var obj = {};
    obj.type = 'item';
    obj.id = 'newvalue';
    obj.value = '+ add new';
    obj.label = '+ add new';
    ret.push(obj);
    var tags = gData.getTagsByNamespace(namespaceString);
    if (!tags) {
      return ret;
    }

    if (tags && sort)
      tags.sort(function (a, b) {
        var sa = a.value.toLowerCase();
        var sb = b.value.toLowerCase();
        return sa < sb ? -1 : 1;
      });

    for (var i = 0; i < tags.length; i++) {
      var obj = {};
      obj.type = 'item';
      obj.id = tags[i].id;
      obj.value = tags[i].value;
      obj.label = tags[i].value;
      obj.tag = tags[i];
      ret.push(obj);
      if (
        namespaceString == 'discipline' &&
        _i.user.defaultDiscipline &&
        _i.user.defaultDiscipline.id == tags[i].id
      ) {
        _i.initial_discipline_value = i + 1;
        obj.selected = true;
      }
      if (
        namespaceString == 'resource type' &&
        _i.user.defaultRole &&
        _i.user.defaultRole.id == tags[i].id
      ) {
        _i.initial_role_value = i + 1;
        obj.selected = true;
      }
      if (
        namespaceString == 'location' &&
        _i.user.location &&
        _i.user.location.id == tags[i].id
      ) {
        _i.initial_location = i + 1;
        obj.selected = true;
      }
    }
    return ret;
  };

  _i.popupDataFromUserTypes = function () {
    var ret = [];
    var types = gData.userTypes;
    var offset = 0;
    for (var i = 0; i < types.length; i++) {
      //NB this only works because we are excluding admin which is greater than all the
      // other types
      if (types[i].id < gData.getMe().user_type_id) {
        continue;
      }
      obj = {};
      obj.type = 'item';
      obj.id = types[i].id;
      obj.value = offset;
      obj.selected = false;
      obj.label = types[i].value;
      ret.push(obj);
      if (obj.id == _i.user.user_type_id) {
        obj.selected = true;
        _i.usertypeid = obj.id;
        _i.usertypeinitialvalue = offset;
      } else {
        obj.selected = false;
      }
      offset++;
    }
    return ret;
  };

  _i.startDateSelected = function (date) {
    _i.start_date = date;
    _i.startdate.setDate(date);
  };

  _i.endDateSelected = function (date) {
    _i.end_date = date;
    _i.enddate.setDate(date);
  };

  _i.anyChangedLRFields = function () {
    for (const changed of Object.values(_i.changedFields)) {
      if (changed) {
        return true;
      }
    }
    return false;
  };

  _i.createLROnChangeListener = function (fieldName, initialValue) {
    if (
      !(_i.userEntitlementsEnabled && _i.isCribEligible) ||
      !_i.userIsLimitedResource
    ) {
      return () => {};
    }

    return _.debounce(function (newValue) {
      // stringify before checking, in case of non-primitive type
      _i.changedFields[fieldName] =
        JSON.stringify(newValue) !== JSON.stringify(initialValue);

      if (_i.anyChangedLRFields()) {
        _i.entitlementsBanner.style.display = 'flex';
        _i.editUserSaveButton &&
          _i.editUserSaveButton.setButtonText(I18n.t('lbl_save_and_upgrade'));
      } else {
        _i.entitlementsBanner.style.display = 'none';
        _i.editUserSaveButton &&
          _i.editUserSaveButton.setButtonText(I18n.t('lbl_save'));
      }
    }, 400);
  };

  _i.addAdminFormElements = function (contentcontainer) {
    var formDiv = document.createElement('DIV');
    formDiv.className = 'blockFloatNot userPageEditAdminForm';
    formDiv.id = 'userPageEditAdminForm';
    contentcontainer.appendChild(formDiv);

    if (_i.user.billable) {
      settingsYesNo[0].selected = false;
      settingsYesNo[1].selected = true;
    } else {
      settingsYesNo[1].selected = false;
      settingsYesNo[0].selected = true;
    }
    _i.initial_discipline_value = null;
    _i.disciplineData = _i.parseTagsForPopup('discipline', true);
    _i.initial_role_value = null;
    _i.roleData = _i.parseTagsForPopup('resource type', true);

    if (
      gData.accountSettings.canUserEditModel(
        gData.getMe(),
        'AccountSetting',
        null
      )
    ) {
      var label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-b-14 adduserAdminFormLabel';
      label.innerHTML = I18n.t('lbl_adminstrator_fields');
      formDiv.appendChild(label);

      var me = gData.getMe();
      if (me && me.id != _i.user.id) {
        _i.userTypeData = _i.popupDataFromUserTypes();
        var height = 32 * Math.min(8, _i.userTypeData.length); // total of 8 possible user types
        const userTypeLRChangeListener = _i.createLROnChangeListener(
          'userType',
          _i.usertypeinitialvalue
        );
        _i.userTypePopup = new selectionPopup(
          200,
          height,
          'mainCon',
          _i.userTypeData,
          compSpritesButtonsIcons.wideDialogSpecs,
          function (obj) {
            _i.onUserTypeChanged(obj);
            if (obj[0].id !== null) {
              userTypeLRChangeListener(obj[0].value);
            }
          },
          false,
          null /*blurCallback*/,
          'nw',
          null /*prefferedDirections*/,
          true /*noautorotate*/
        );

        label = document.createElement('DIV');
        label.className = 'blockFloatNot fnt-b-14 employmentHeaderContainer';
        label.innerHTML = I18n.t('lbl_permission_level');
        formDiv.appendChild(label);

        _i.userType = new PopUpInput(
          'userPageEditAdminForm',
          null,
          _i.usertypeinitialvalue,
          'blockFloatNot personPageEditInputContainerLarge',
          'blockFloatNot fnt-r-14 userEditInputContainer',
          'blockFloatNot fnt-r-13 personPageTextInputLabel',
          'blockFloatNot fnt-r-14',
          _i.userTypeData,
          _i.userTypePopup
        );
      }

      var employment = document.createElement('DIV');
      employment.className = 'blockFloatNot employmentContentContainer';
      employment.id = 'employmentContainerId';
      formDiv.appendChild(employment);

      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-b-14 employmentHeaderContainer';
      label.innerHTML = I18n.t('lbl_availability');
      employment.appendChild(label);

      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 availabilityDescription';
      label.innerHTML = I18n.t('msg_availability_description');
      employment.appendChild(label);

      _i.start_date = _i.user.hire_date;
      var start = _i.start_date ? _i.start_date : new Date();
      const startDateLRChangeListener = _i.createLROnChangeListener(
        'startDate',
        _i.start_date === undefined
          ? null // coalesce null and undefined start states to ensure consistency
          : _i.start_date
      );
      _i.startPopup = new calendarPopup(
        230,
        210,
        'employmentContainerId',
        start,
        compSpritesButtonsIcons.wideDialogSpecsGray,
        function (date) {
          _i.startDateSelected(date);
          startDateLRChangeListener(date);
        },
        false,
        false,
        'ne',
        true,
        null,
        null,
        null,
        null,
        defaultCalendarShortcutNone
      );

      _i.startdate = new PopUpCalendar(
        employment.id,
        I18n.t('lbl_first_day_of_work'),
        'blockFloatNot personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-14 userEditInputContainer',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 availabilityInputDateText',
        _i.start_date,
        _i.startPopup,
        true
      );

      var linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat personLinespacer';
      employment.appendChild(linespacer);

      _i.end_date = _i.user.termination_date;
      var end = _i.end_date ? _i.end_date : new Date();
      const endDateLRChangeListener = _i.createLROnChangeListener(
        'endDate',
        _i.end_date === undefined
          ? null // coalesce null and undefined start states to ensure consistency
          : _i.end_date
      );
      _i.endPopup = new calendarPopup(
        230,
        210,
        'employmentContainerId',
        end,
        compSpritesButtonsIcons.wideDialogSpecsGray,
        function (date) {
          _i.endDateSelected(date);
          endDateLRChangeListener(date);
        },
        false,
        false,
        'ne',
        true,
        null,
        null,
        null,
        null,
        defaultCalendarShortcutNone
      );

      _i.enddate = new PopUpCalendar(
        employment.id,
        I18n.t('lbl_last_day_of_work'),
        'blockFloat personPageEditInputContainerSmall',
        'blockFloat fnt-r-14 userEditInputContainer',
        'blockFloat fnt-r-13 personPageTextInputLabel',
        'blockFloat fnt-r-15 availabilityInputDateText',
        _i.end_date,
        _i.endPopup,
        true
      );

      _i.availability = AvailabilityView(
        employment,
        _i.user,
        _i.createLROnChangeListener
      );

      linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat personLinespacer';
      employment.appendChild(linespacer);

      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-b-14 employmentHeaderContainer';
      label.innerHTML = I18n.t('lbl_utilization');
      employment.appendChild(label);

      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 availabilityDescription';
      label.innerHTML = I18n.t('msg_utilization_description');
      employment.appendChild(label);

      // Need to cast target to string here because _i.user.billability_target is a number but anything entered will be a string
      const billabilityLRChangeListener = _i.createLROnChangeListener(
        'billabilityTarget',
        String(_i.user.billability_target)
      );
      _i.billabilitytarget = new TextInput(
        employment,
        I18n.t('lbl_utilization_target_and_symbol'),
        _i.user.billability_target,
        'blockFloatNot personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 personPageInputSmall',
        null,
        TextInputFilter.Integer,
        null,
        null,
        billabilityLRChangeListener
      );

      var label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-b-14 userEditUserSpacerImage1';
      label.innerHTML = I18n.t('lbl_bill_rate');
      formDiv.appendChild(label);

      _i.selectionBillrateDefault = _i.user.billrate == -1;

      const selectionBillRateLRChangeListener = _i.createLROnChangeListener(
        'selectionBillRate',
        !_i.selectionBillrateDefault
      );
      _i.selectionbillratecustom = new SelectionToggle(
        formDiv,
        _i.buildPlainTextSpan(I18n.t('msg_use_custom_billrate')),
        !_i.selectionBillrateDefault,
        'blockFloatNot indexSelectorItemAuto',
        'fnt-r-15 indexSelectorText2 ',
        'indexSelectorIcon',
        null,
        null,
        selectionBillRateLRChangeListener
      );
      _i.selectionbillratecustom.setCallback(_i.toggleCallbackBillrateCustom);
      linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat personLinespacer2';
      formDiv.appendChild(linespacer);
      _i.custombillrate = new TextInput(
        'userPageEditAdminForm',
        '',
        '',
        'blockFloat personPageEditInputContainerTinyBillrate',
        'blockFloatNot fnt-r-13 personPageTextInputLabel visually-hidden',
        'blockFloatNot fnt-r-15 personPageInputTinyBillrate'
      );
      if (_i.user.billrate != -1)
        _i.custombillrate.inputText.value = formatCurrency(_i.user.billrate);
      xAddEventListener(
        _i.custombillrate.inputText,
        platform.click,
        _i.toggleCallbackBillrateCustom,
        false
      );
      _i.selectionbillratedefault = new SelectionToggle(
        formDiv,
        _i.buildPlainTextSpan(I18n.t('msg_use_default_billrate')),
        _i.selectionBillrateDefault,
        'blockFloatNot indexSelectorItemSmall',
        'fnt-r-15 indexSelectorText2 ',
        'indexSelectorIcon'
      );
      _i.selectionbillratedefault.setCallback(_i.toggleCallbackBillrateDefault);

      var height =
        32 *
        (_i.disciplineData.length > _i.max_shown
          ? _i.max_shown
          : _i.disciplineData.length);
      _i.disciplineLRChangeListener = _i.createLROnChangeListener(
        'discipline',
        _i.initial_discipline_value
          ? _i.disciplineData[_i.initial_discipline_value].id
          : null
      );
      _i.disciplinePopup = new selectionPopup(
        190,
        height,
        'mainCon',
        _i.disciplineData,
        compSpritesButtonsIcons.wideDialogSpecs,
        function (obj) {
          _i.onDisciplineChanged(obj);
        },
        false,
        null /*blurCallback*/,
        'nw',
        null /*prefferedDirections*/,
        true /*noautorotate*/
      );

      _i.disciplinePopup.popupContainer.autoOrient = false;
      _i.disciplinePopup.popupContainer.setDirection('ne');
      _i.disciplinePopup.isShown = false;

      _i.discipline = new PopUpTextInput(
        'userPageEditAdminForm',
        I18n.t('lbl_discipline'),
        _i.initial_discipline_value,
        'blockFloatNot personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-14 userEditInputContainer',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 userEditTextInput',
        _i.disciplineData,
        _i.disciplinePopup
      );
      _i.discipline.setConfirmedCallback(_i.disciplineLRChangeListener);

      var linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat personLinespacer';
      formDiv.appendChild(linespacer);
      height =
        32 *
        (_i.roleData.length > _i.max_shown ? _i.max_shown : _i.roleData.length);
      _i.roleLRChangeListener = _i.createLROnChangeListener(
        'role',
        _i.initial_role_value ? _i.roleData[_i.initial_role_value].id : null
      );
      _i.rolePopup = new selectionPopup(
        190,
        height,
        'mainCon',
        _i.roleData,
        compSpritesButtonsIcons.wideDialogSpecs,
        function (obj) {
          _i.onRoleChanged(obj);
        },
        false,
        null /*blurCallback*/,
        'nw',
        null /*prefferedDirections*/,
        true /*noautorotate*/
      );

      _i.rolePopup.popupContainer.autoOrient = false;
      _i.rolePopup.popupContainer.setDirection('ne');
      _i.rolePopup.isShown = false;
      _i.role = new PopUpTextInput(
        'userPageEditAdminForm',
        I18n.t('lbl_role'),
        _i.initial_role_value,
        'blockFloat personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-14 userEditInputContainer',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 userEditTextInput',
        _i.roleData,
        _i.rolePopup
      );
      _i.role.setConfirmedCallback(_i.roleLRChangeListener);

      var adminCustomFieldsAreaDiv = document.createElement('DIV');
      adminCustomFieldsAreaDiv.className =
        'blockFloatNot personPageCustomFieldsEditContainer';
      formDiv.appendChild(adminCustomFieldsAreaDiv);
      if (gData.accountSettings.moduleEnabled('custom fields')) {
        // In the admin edit view, return only fields editable by admins.
        var isEditableCallback = function (field) {
          return field.is_editable_only_by_admins;
        };
        _i.adminCustomFieldsEdit = new CustomFieldsEditView(
          'Custom Fields',
          'users',
          _i.user.id,
          {
            isEditableCallback: isEditableCallback,
            createLROnChangeListener: _i.createLROnChangeListener,
          }
        );
        _i.adminCustomFieldsEdit.show(adminCustomFieldsAreaDiv);
      }
    } else {
      _i.billable = new TagView(
        formDiv,
        'Billable',
        _i.user.billable ? 'Yes' : 'No',
        'blockFloatNot fnt-r-13 personTagContainer',
        'personInfoTagName',
        'fnt-r-15 personInfoTagValue'
      );
      var initialvalue = '';
      if (
        _i.initial_discipline_value != null &&
        _i.disciplineData[_i.initial_discipline_value] != null &&
        _i.disciplineData[_i.initial_discipline_value].value != null
      ) {
        initialvalue = _i.disciplineData[_i.initial_discipline_value].value;
      }
      _i.discipline = new TagView(
        formDiv,
        'Discipline',
        initialvalue,
        'blockFloatNot fnt-r-13 personTagContainer',
        'personInfoTagName',
        'fnt-r-15 personInfoTagValue'
      );

      var linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat indexLinespacer';
      formDiv.appendChild(linespacer);

      initialvalue = '';
      if (
        _i.initial_role_value != null &&
        _i.roleData[_i.initial_role_value] != null &&
        _i.roleData[_i.initial_role_value].value != null
      ) {
        initialvalue = _i.roleData[_i.initial_role_value].value;
      }
      _i.role = new TagView(
        formDiv,
        'Role',
        initialvalue,
        'blockFloat fnt-r-13 personTagContainer',
        'personInfoTagName',
        'fnt-r-15 personInfoTagValue'
      );
    }

    // Approval Workflow
    if (
      gData.accountSettings.moduleEnabled('relationship-based approvals') &&
      gData.getMe().user_type_id === 1
    ) {
      var approvalWorkflowlabel = document.createElement('DIV');
      approvalWorkflowlabel.className =
        'blockFloatNot fnt-b-14 userEditUserSpacerImage1';
      approvalWorkflowlabel.innerHTML = I18n.t('lbl_approval_workflow');
      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 availabilityDescription';
      label.innerHTML = I18n.t('msg_approval_select_description');
      approvalWorkflowlabel.appendChild(label);
      formDiv.appendChild(approvalWorkflowlabel);

      var labelText = document.createElement('DIV');
      labelText.className = 'blockFloatNot fnt-r-13 defaultTextInputLabel';
      labelText.setAttribute('id', 'approval-workflow-button-label');
      labelText.style.marginTop = '15px';
      labelText.innerText = I18n.t('lbl_approvers');
      formDiv.appendChild(labelText);

      var approverMenu = document.createElement('DIV');
      approverMenu.className = 'blockFloatNot';
      formDiv.appendChild(approverMenu);

      // load approver mapping menu
      if (typeof window.loadApproverUI === 'function') {
        window.loadApproverUI(
          approverMenu,
          _i.user && _i.user.approver_user_ids,
          'approval-workflow-button-label',
          _i.createLROnChangeListener
        );
      }

      if (window.accountSettings.project_based_approvals === '1') {
        var projectOwnerApprovalLabel = document.createElement('DIV');
        projectOwnerApprovalLabel.className = 'blockFloatNot fnt-r-12';
        projectOwnerApprovalLabel.innerHTML = I18n.t(
          'msg_project_owner_approval_description'
        );
        projectOwnerApprovalLabel.style.marginTop = '15px';
        formDiv.appendChild(projectOwnerApprovalLabel);
      }
    }

    if (gData.accountSettings.canUserEditModel(gData.getMe(), 'User', null)) {
      var tagAreaDiv = document.createElement('DIV');
      tagAreaDiv.className = 'blockFloatNot personPageTagEditContainer';
      formDiv.appendChild(tagAreaDiv);

      headerdiv = document.createElement('DIV');
      headerdiv.className = 'blockFloatNot fnt-b-15 adduserAdminFormLabel';
      headerdiv.innerHTML = I18n.t('lbl_tags');
      tagAreaDiv.appendChild(headerdiv);

      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 personPageTagEditLabel';
      label.innerHTML = I18n.t('msg_add_tags_to_user');
      tagAreaDiv.appendChild(label);

      var tagEditContainer = document.createElement('DIV');
      tagEditContainer.className = 'blockFloatNot personPageTagAreaDiv';
      tagAreaDiv.appendChild(tagEditContainer);

      const tagLRListeners = {};
      _i.tagEdit = new TagEditView(
        function () {
          var currentTags = [];
          _i.user.tags.forEach(function (tag) {
            if (tag.namespace == 'users') {
              currentTags.push(tag);
            }
          });
          return currentTags;
        },
        function () {
          return gData.tagsByNameSpace['users'];
        },
        function (tags) {
          var newTags = [];
          _i.user.tags.forEach(function (tag) {
            if (tag.namespace != 'users') {
              newTags.push(tag);
            }
          });
          tags.forEach(function (tag) {
            newTags.push(tag);
          });
          newTags.forEach((newTag) => {
            if (!tagLRListeners[newTag.id]) {
              tagLRListeners[newTag.id] = _i.createLROnChangeListener(
                `tag${newTag.id}`,
                _i.user.tags.some((existingTag) => existingTag.id === newTag.id)
              );
            }
            tagLRListeners[newTag.id](true);
          });
          _i.user.tags.forEach((existingTag) => {
            if (!newTags.some((newTag) => newTag.id === existingTag.id)) {
              if (!tagLRListeners[existingTag.id]) {
                tagLRListeners[existingTag.id] = _i.createLROnChangeListener(
                  `tag${existingTag.id}`,
                  true
                );
              }
              tagLRListeners[existingTag.id](false);
            }
          });
          _i.user.tags = newTags;
        },
        !+gData.accountSettings.restricted_tag_mode /* canCreateNewTags */,
        'users'
      );

      _i.tagEdit.show(tagEditContainer);
    }

    _i.addSpacer(formDiv, 'blockFloatNot personLinespacer3');
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot userEditUserSpacerImage1';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.grayLine));
    formDiv.appendChild(spacer);

    var buttonContainer = document.createElement('DIV');
    buttonContainer.className =
      'blockFloatNotChromeFix userEditUserButtonContainer';
    formDiv.appendChild(buttonContainer);

    if (
      gData.accountSettings.canUserEditModel(
        gData.getMe(),
        'AccountSetting',
        null
      )
    ) {
      var me = gData.getMe();

      if (me && me.id != _i.user.id) {
        if (_i.user.deleted) {
          _i.deleteButton = new secondaryButton(
            'blockFloatNot userEditAdminButton'
          );
          _i.deleteButton.setButtonText(I18n.t('lbl_delete'));
          _i.deleteButton.setCallback(_i.deleteButtonClick);
          buttonContainer.appendChild(_i.deleteButton.container);
          _i.deleteButton.container.tabIndex = 0;
          _i.archive = new secondaryButton('blockFloat userEditAdminButton');
          _i.archive.setButtonText(I18n.t('lbl_unarchive'));
        } else {
          _i.archive = new secondaryButton('blockFloatNot userEditAdminButton');
          _i.archive.setButtonText(I18n.t('lbl_archive'));
        }
        _i.archive.setCallback(_i.archiveButtonClick);
        buttonContainer.appendChild(_i.archive.container);
        _i.archive.container.tabIndex = 0;
      }

      if (!_i.user.deleted) {
        if (_i.user.login) {
          if (!gData.accountSettings.moduleEnabled('sso')) {
            _i.action = new secondaryButton('blockFloat userEditAdminButton');
            _i.action.setCallback(_i.resetPassword);
            buttonContainer.appendChild(_i.action.container);
            _i.action.setButtonText(I18n.t('lbl_reset_password'));
          }
        } else {
          // users without a login get the option to (re)send an invitation
          if (_i.user.license_type === 'licensed') {
            _i.action = new secondaryButton('blockFloat userEditAdminButton');
            _i.action.setCallback(_i.sendInvitationEmail);
            buttonContainer.appendChild(_i.action.container);
            _i.action.container.tabIndex = 0;
            if (_i.user.invitation_pending) {
              _i.action.setButtonText(I18n.t('lbl_resend_invitation'));
            } else {
              _i.action.setButtonText(I18n.t('lbl_send_invitation'));
            }
          }
        }
      }
    }

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    buttonContainer.appendChild(_i.nestedDiv);

    _i.editUserCancelButton = new secondaryButton('userEditAdminButton');
    _i.editUserCancelButton.setButtonText(I18n.t('lbl_cancel'));
    _i.editUserCancelButton.setCallback(_i.userCancelButtonCallback);
    _i.nestedDiv.appendChild(_i.editUserCancelButton.container);
    _i.editUserCancelButton.container.tabIndex = 0;

    _i.editUserSaveButton = new primaryButton('userEditAdminButtonSave');
    _i.editUserSaveButton.setButtonText(I18n.t('lbl_save'));
    _i.editUserSaveButton.setCallback(_i.userSaveButtonCallback);
    _i.nestedDiv.appendChild(_i.editUserSaveButton.container);
    _i.editUserSaveButton.container.tabIndex = 0;

    _i.span = document.createElement('DIV');
    _i.span.style.visibility = 'hidden';
    _i.span.className = 'blockFloatNot fnt-r-15 measurespan';
    formDiv.appendChild(_i.span);
  };

  _i.addUserFormElements = function (contentcontainer) {
    var formDiv = document.createElement('DIV');
    formDiv.className = 'blockFloatNot';
    formDiv.id = 'userPageEditUserForm';
    contentcontainer.appendChild(formDiv);

    if (_i.userEntitlementsEnabled && _i.isCribEligible) {
      var linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat personLinespacer';
      formDiv.appendChild(linespacer);

      var generalHeader = document.createElement('DIV');
      generalHeader.className =
        'blockFloatNot fnt-b-14 editUserGeneralFormLabel';
      generalHeader.innerHTML = I18n.t('lbl_general');
      generalHeader.style.width = '100%';
      generalHeader.style.marginBottom = 0; // To counteract the oversized marginBottom it gets by default
      formDiv.appendChild(generalHeader);
    }

    if (!(whoami.syncs_with_smar || _i.allowProfileUpdates)) {
      _i.firstName = new TextInput(
        'userPageEditUserForm',
        I18n.t('lbl_first_name'),
        _i.user.firstName,
        'blockFloat personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 personPageInputSmall'
      );
    } else {
      var lock_desc = document.createElement('DIV');
      lock_desc.className = 'blockFloatNot lock_descContentContainer';
      lock_desc.id = 'lock_descContainerId';
      formDiv.appendChild(lock_desc);

      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 lockDescription';
      label.innerHTML = I18n.t('msg_locked_fields_managed_in_smar').replace(
        '{helpLink}',
        'https://help.smartsheet.com/articles/2482610-use-smartsheet-authentication-resource-management#toc-user-profile-updates'
      );
      lock_desc.appendChild(label);

      var d, h, i;

      d = document.createElement('DIV');
      d.className = 'blockFloat personPageEditInputContainerSmall';
      h = document.createElement('LABEL');
      h.className = 'blockFloatNot fnt-r-13 personPageTextInputLabel';
      h.setAttribute('for', 'user-first_name');
      const firstNameLabelId = _i.allowProfileUpdates
        ? 'lbl_first_name'
        : 'msg_first_name_locked_for_user';
      h.innerHTML = `${I18n.t(firstNameLabelId)}`;
      d.appendChild(h);
      i = document.createElement('INPUT');
      i.className = 'blockFloatNot fnt-r-15 personPageInputSmall';
      i.setAttribute('id', 'user-first_name');
      i.style.color = '#808080';
      i.value = _i.user.firstName;
      i.readOnly = true;
      d.appendChild(i);
      formDiv.appendChild(d);
    }

    var linespacer = document.createElement('DIV');
    linespacer.className = 'blockFloat personLinespacer';
    formDiv.appendChild(linespacer);

    if (!(whoami.syncs_with_smar || _i.allowProfileUpdates)) {
      _i.lastName = new TextInput(
        'userPageEditUserForm',
        I18n.t('lbl_last_name'),
        _i.user.lastName,
        'blockFloat personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 personPageInputSmall'
      );
    } else {
      var d, h, i;

      d = document.createElement('DIV');
      d.className = 'blockFloat personPageEditInputContainerSmall';
      h = document.createElement('LABEL');
      h.className = 'blockFloatNot fnt-r-13 personPageTextInputLabel';
      h.setAttribute('for', 'user-last_name');
      const lastNameLabelId = _i.allowProfileUpdates
        ? 'lbl_last_name'
        : 'msg_last_name_locked_for_user';
      h.innerHTML = `${I18n.t(lastNameLabelId)}`;
      d.appendChild(h);
      i = document.createElement('INPUT');
      i.className = 'blockFloatNot fnt-r-15 personPageInputSmall';
      i.setAttribute('id', 'user-last_name');
      i.style.color = '#808080';
      i.value = _i.user.lastName;
      i.readOnly = true;
      d.appendChild(i);
      formDiv.appendChild(d);
    }

    if (
      !(
        (_i.user.login && gData.accountSettings.moduleEnabled('sso')) ||
        (_i.userEntitlementsEnabled &&
          _i.userIsLimitedResource &&
          _i.isCribEligible)
      )
    ) {
      _i.email = new TextInput(
        'userPageEditUserForm',
        I18n.t('lbl_email'),
        _i.user.email,
        'blockFloat personPageEditInputContainerLarge',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 personPageInputLarge'
      );
    } else {
      var d, h, i;

      d = document.createElement('DIV');
      d.className = 'blockFloat personPageEditInputContainerLarge';
      h = document.createElement('LABEL');
      h.className = 'blockFloatNot fnt-r-13 personPageTextInputLabel';
      h.setAttribute('for', 'user-email');
      const emailLabelId = _i.allowProfileUpdates
        ? 'lbl_email'
        : 'msg_email_locked_for_user';
      h.innerHTML = `${I18n.t(emailLabelId)}`;
      d.appendChild(h);
      i = document.createElement('INPUT');
      i.className = 'blockFloatNot fnt-r-15 personPageInputLarge';
      i.setAttribute('id', 'user-email');
      i.style.color = '#808080';
      i.value = _i.user.email;
      i.readOnly = true;
      d.appendChild(i);
      formDiv.appendChild(d);
    }

    if (!_i.allowProfileUpdates) {
      _i.mobilePhone = new TextInput(
        'userPageEditUserForm',
        I18n.t('lbl_cell_phone'),
        _i.user.mobile_phone,
        'blockFloat personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 personPageInputSmall'
      );

      var linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat personLinespacer';
      formDiv.appendChild(linespacer);

      _i.officePhone = new TextInput(
        'userPageEditUserForm',
        I18n.t('lbl_office_phone'),
        _i.user.office_phone,
        'blockFloat personPageEditInputContainerSmall',
        'blockFloatNot fnt-r-13 personPageTextInputLabel',
        'blockFloatNot fnt-r-15 personPageInputSmall'
      );
    } else {
      var d, h, i;

      d = document.createElement('DIV');
      d.className = 'blockFloat personPageEditInputContainerSmall';
      h = document.createElement('LABEL');
      h.className = 'blockFloatNot fnt-r-13 personPageTextInputLabel';
      h.setAttribute('for', 'user-mobile-phone');
      h.innerHTML = `${I18n.t('lbl_cell_phone')}`;
      d.appendChild(h);
      i = document.createElement('INPUT');
      i.className = 'blockFloatNot fnt-r-15 personPageInputSmall';
      i.setAttribute('id', 'user-mobile-phone');
      i.style.color = '#808080';
      i.value = _i.user.mobile_phone;
      i.readOnly = true;
      d.appendChild(i);
      formDiv.appendChild(d);

      var linespacer = document.createElement('DIV');
      linespacer.className = 'blockFloat personLinespacer';
      formDiv.appendChild(linespacer);

      d = document.createElement('DIV');
      d.className = 'blockFloat personPageEditInputContainerSmall';
      h = document.createElement('LABEL');
      h.className = 'blockFloatNot fnt-r-13 personPageTextInputLabel';
      h.setAttribute('for', 'user-mobile-phone');
      h.innerHTML = `${I18n.t('lbl_office_phone')}`;
      d.appendChild(h);
      i = document.createElement('INPUT');
      i.className = 'blockFloatNot fnt-r-15 personPageInputSmall';
      i.setAttribute('id', 'user-office-phone');
      i.style.color = '#808080';
      i.value = _i.user.office_phone;
      i.readOnly = true;
      d.appendChild(i);
      formDiv.appendChild(d);
    }

    _i.locationsData = _i.parseTagsForPopup('location', true);
    var height =
      32 *
      (_i.locationsData.length > _i.max_shown
        ? _i.max_shown
        : _i.locationsData.length);
    _i.locationLRChangeListener = _i.createLROnChangeListener(
      'location',
      _i.initial_location ? _i.locationsData[_i.initial_location].id : null
    );
    _i.locationPopup = new selectionPopup(
      190,
      height,
      'mainCon',
      _i.locationsData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onLocationChanged,
      false,
      null /*blurCallback*/,
      'nw',
      null /*prefferedDirections*/,
      true /*noautorotate*/
    );

    _i.locationPopup.popupContainer.autoOrient = false;
    _i.locationPopup.popupContainer.setDirection('ne');
    _i.locationPopup.isShown = false;
    _i.location = new PopUpTextInput(
      'userPageEditUserForm',
      I18n.t('lbl_location'),
      _i.initial_location,
      'blockFloat personPageEditInputContainerSmall',
      'blockFloatNot fnt-r-14 userEditInputContainer',
      'blockFloatNot fnt-r-13 personPageTextInputLabel',
      'blockFloatNot fnt-r-15 userEditTextInput',
      _i.locationsData,
      _i.locationPopup
    );
    _i.location.setConfirmedCallback(_i.locationLRChangeListener);

    _i.originalTagString = _i.parseTagsForString();

    linespacer = document.createElement('DIV');
    linespacer.className = 'blockFloat personLinespacer2';
    formDiv.appendChild(linespacer);

    const employeeNumberLRChangeListener = _i.createLROnChangeListener(
      'employeenumber',
      _i.user.employee_number
    );
    _i.employeeNumber = new TextInput(
      'userPageEditUserForm',
      I18n.t('lbl_employee_number'),
      _i.user.employee_number,
      'blockFloat personPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 personPageTextInputLabel',
      'blockFloatNot fnt-r-15 personPageInputSmall',
      null,
      null,
      null,
      null,
      employeeNumberLRChangeListener
    );

    _i.addSpacer(formDiv, 'blockFloatNot personLinespacer3');

    var customFieldsAreaDiv = document.createElement('DIV');
    customFieldsAreaDiv.className =
      'blockFloatNot personPageCustomFieldsEditContainer';
    formDiv.appendChild(customFieldsAreaDiv);
    if (gData.accountSettings.moduleEnabled('custom fields')) {
      // In the 'public' edit view, return only fields not editable by admins.
      var isEditableCallback = function (field) {
        return !field.is_editable_only_by_admins;
      };
      _i.customFieldsEdit = new CustomFieldsEditView(
        'Custom Fields',
        'users',
        _i.user.id,
        {
          isEditableCallback: isEditableCallback,
          createLROnChangeListener: _i.createLROnChangeListener,
        }
      );
      _i.customFieldsEdit.show(customFieldsAreaDiv);
    } else {
      $(customFieldsAreaDiv)
        .css({
          marginTop: 20,
        })
        .append(
          '<div class="blockFloatNot fnt-b-14">Custom Fields</div>' +
            '<div class="blockFloatNot fnt-r-14 expenseHeaderLabel">' +
            '<a href="' +
            APP_ENDPOINT +
            '/billing" target="_blank">' +
            I18n.t('lbl_upgrade_to_professional') +
            '</a>' +
            I18n.t('lbl_customize_people_options') +
            '  <a href="https://help.smartsheet.com/articles/2480866" target="_blank">' +
            I18n.t('lbl_learn_more') +
            '</a>' +
            '</div>'
        );
    }
  };

  _i.addThumbnailSelectionElements = function (contentcontainer) {
    var thumbnailSelectionDiv = document.createElement('DIV');
    thumbnailSelectionDiv.className =
      'blockFloatNotChromeFix personPageEditThumbnailContainer';
    thumbnailSelectionDiv.id = 'personPageEditThumbnailContainer';

    var thumbnailDiv = document.createElement('DIV');
    thumbnailDiv.className = 'blockFloat';
    thumbnailDiv.id = 'personPageEditThumbnail';
    contentcontainer.appendChild(thumbnailSelectionDiv);
    thumbnailSelectionDiv.appendChild(thumbnailDiv);

    _i.userThumb = new UserThumbnailLargeView(
      'personPageEditThumbnail',
      _i.user
    );
    if (_i.userEntitlementsEnabled && _i.isCribEligible) {
      _i.userThumb.userThumbnailView.style.backgroundImage = ''; // Remove the background that gets added by default
      _i.userThumb.userThumbnailView.style.backgroundColor = '#FFFFFF';
      _i.userThumb.userThumbnailView.style.filter =
        'drop-shadow(0px 2px 6px #DDDDDD)';
    }

    var userCanUploadThumbnail =
      _i.allowProfileUpdates || !whoami.syncs_with_smar;

    if (userCanUploadThumbnail) {
      var thumbnailSelectionText = document.createElement('DIV');
      thumbnailSelectionText.className =
        'blockFloat fnt-r-15 personPageEditThumbnailText';
      thumbnailSelectionText.id = 'thumbnailSelectionText';
      thumbnailSelectionDiv.appendChild(thumbnailSelectionText);

      var textdiv = document.createElement('DIV');
      textdiv.className = 'blockFloatNot personPageDarkText';
      textdiv.setAttribute('id', 'select-image-button-desc');
      textdiv.innerHTML = I18n.t('msg_select_image');
      thumbnailSelectionText.appendChild(textdiv);

      _i.awsUpload = new AWSCropprImageUpload(
        document.getElementById('thumbnailSelectionText'),
        gService.getS3CredentialsUserImage,
        _i.user.id,
        'public-read',
        _i.userThumb,
        _i.userImagePixelDim,
        _i.userImagePixelDim,
        'user'
      );
    }

    _i.thumbnailSelectionText = thumbnailSelectionText;
  };

  _i.addSpacer = function (container, classnameStr, sprite, text) {
    var spacer = document.createElement('DIV');
    spacer.className = classnameStr;
    if (sprite) spacer.appendChild(sprite);
    if (text) spacer.innerText = text;
    container.appendChild(spacer);
    return spacer;
  };

  _i.addEntitlementsBanner = function (attachTo) {
    _i.entitlementsBanner = document.createElement('DIV');
    _i.entitlementsBanner.id = 'userEntitlementsBanner';
    _i.entitlementsBanner.style = `
      background-color: #cae8fc;
      width: 100%;
      min-height: 50px;
      padding: 12px 12px 12px 12px;
      display: none;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    `;
    if (window.isIframeView) {
      _i.entitlementsBanner.style.position = 'fixed';
      _i.entitlementsBanner.style.inset = '0 auto auto 0';
      _i.entitlementsBanner.style.zIndex = '101'; // places it over edituser dropdowns
    }
    attachTo.appendChild(_i.entitlementsBanner);

    const infoIcon = new InfoIconSVG(_i.entitlementsBanner);

    const entitlementsBannerText = document.createElement('DIV');
    entitlementsBannerText.innerHTML = I18n.t(
      'lbl_profile_editor_user_entitlements_banner'
    ).replace(
      '{helpLink}',
      'https://help.smartsheet.com/articles/2483174-unmanaged-resources#toc-upgrade-an-unmanaged-resource'
    );
    entitlementsBannerText.style = `margin: 0 0 0 0;`;
    _i.entitlementsBanner.appendChild(entitlementsBannerText);
  };

  _i.loadEditUserPage = function () {
    var attachTo = xGetElementById('mainCon');

    var personPageUserInfoContainer = document.createElement('DIV');
    personPageUserInfoContainer.className =
      'personPageEditUserContainer blockFloatNot';
    personPageUserInfoContainer.id = 'personPageEditUserContainer';
    attachTo.appendChild(personPageUserInfoContainer);

    var container = document.createElement('DIV');
    container.id = 'personPageEditFloat';
    container.className = 'personPageEditFloat';
    personPageUserInfoContainer.appendChild(container);

    var contentcontainer = document.createElement('MAIN');
    contentcontainer.className = 'blockFloatNot personPageEditContentContainer';
    contentcontainer.id = 'personPageContentContainer';
    container.appendChild(contentcontainer);

    var headerDiv = document.createElement('H1');
    headerDiv.className = 'blockFloatNot fnt-b-30';
    headerDiv.innerHTML = I18n.t('lbl_update_profile');
    contentcontainer.appendChild(headerDiv);

    var entitlementsBannerParent = window.isIframeView
      ? contentcontainer
      : xGetElementById('pageHdr');
    _i.addEntitlementsBanner(entitlementsBannerParent);
    _i.addThumbnailSelectionElements(contentcontainer);
    _i.addUserFormElements(contentcontainer);
    _i.addAdminFormElements(contentcontainer);
  };

  _i.allDataLoaded = function (obj) {
    _i.loadEditUserPage();
    gDataLoading = false;
    if (gPageHeader) gPageHeader.setSpinner(false);
    gPageLoaded = true;
  };
}

function startup() {
  gPage = new IndexPage();
  gPage.initPage();
}
